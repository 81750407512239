<template>
    <div class="step_box">
        <img :src="step_img" alt="">
    </div>
</template>

<script>
export default {
    data(){
        return{
            step_img:require('@/assets/imgs/step.png')
        }
    }
}
</script>

<style lang="less" scoped>
    .step_box{
        img{
            width: 100%;
        }
    }
</style>